<template>
  <div class="error">
    <div class="message">
      <img src="@/assets/img/logo-gray.svg" alt="logo">
      <div class="title">Error 404</div>
      <div class="text">Page not found</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  height: 100vh;
  padding: 10rem $Gpadding $Gpadding $Gpadding;
  text-align: center;
  @include M {
    padding: 10rem 10rem 0 10rem;
  }
  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    .title {
      color: red;
      font-size: 3rem;
      padding: 2rem;
    }
  }
  
}
</style>
